/**
 * @file standalone/sd_product.scss
 *
 * \brief Library file - shared styles for stardust product pages
 *
 */

// 2019 Redesign
// Base library for shared styles for the new sd product pages.
// Lib is attached per template as needed.

@import '../theme-bootstrap';

$loyalty_program: false !default;
$foundation-finder-max-width: 1016px;

// catch-all for product react components
// this will not get everything but it appliees very basic typography change
[class*='elc-dropdown'],
[class*='elc-label'],
[class*='elc-spinner'] {
  [data-component] & {
    // specificity for react
    @include text-body-text;
  }
}

[class*='elc-heading'] {
  [data-component] &,
  .elc-olapic-v1__header & {
    @include text-body-text($font-family: $helvetica-bold-font-family);
  }
}

.elc-olapic-v1__header {
  .elc-olapic-v1__heading {
    font-family: $helvetica-bold-font-family;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.091;
    margin-bottom: 0.25rem;
    @media #{$cr19-medium-up} {
      font-size: 2.25rem;
      letter-spacing: unset;
      line-height: 1;
    }
  }
  .elc-olapic-v1__subtitle {
    font-family: $helvetica-regular-font-family;
    line-height: 1.25;
    @media #{$cr19-medium-up} {
      line-height: 1.5;
    }
  }
}

.ugc-headings {
  &__heading,
  &__subheading {
    color: $cr19-text;
  }
  &__heading {
    font-family: $helvetica-bold-font-family;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.091;
    margin-bottom: 0.25rem;
    @media #{$cr19-medium-up} {
      font-size: 2.25rem;
      letter-spacing: unset;
      line-height: 1;
    }
  }
  &__subheading {
    font-family: $helvetica-regular-font-family;
    font-size: 1rem;
    line-height: 1.25;
    @media #{$cr19-medium-up} {
      line-height: 1.5;
    }
  }
}

.elc-body {
  &--1,
  &--2 {
    [data-component] & {
      @include text-body-text--fine($font-family: $helvetica-bold-font-family);
      color: $cr19-text;
    }
  }
}

.elc-subtitle {
  &--1,
  &--2 {
    [data-component] & {
      font-family: $base-bolder-font-family;
    }
  }
  &--3 {
    [data-component] & {
      font-family: $base-font-family-roman;
    }
  }
}

select {
  [data-component] & {
    font-family: $base-font-family-roman;
  }
}

.elc-vto-foundation-overlay {
  .elc-checkbox {
    display: flex;
    margin-bottom: 25px;
  }
  .elc-checkbox-selected .elc-checkbox-icon {
    @include svg-icon-inline-mask('checkbox-selected.svg');
  }
  .elc-checkbox-not-selected .elc-checkbox-icon {
    @include svg-icon-inline-mask('checkbox-unselected.svg');
  }
}

.elc-image-slider-images-wrapper .elc-video-wrapper,
.elc-product-image-list .elc-video-wrapper {
  .elc-play-icon-wrapper {
    background: none;
  }
  .elc-play-icon {
    -webkit-mask: url('#{$base-theme-path}/img/icons/src/new-play-icon.svg') center center no-repeat;
    mask: url('#{$base-theme-path}/img/icons/src/new-play-icon.svg') center center no-repeat;
    background-color: $color-white;
    -webkit-mask-size: contain;
    mask-size: contain;
    border-radius: 0;
  }
}

.elc-breadcrumbs {
  order: 0;
  [data-component] & {
    a,
    p {
      font-family: $base-font-family-roman;
      font-size: 15px;
      letter-spacing: 0.02em;
      line-height: get-line-height(15px, 20px);
      @media #{$cr19-medium-up} {
        font-size: 17px;
        line-height: get-line-height(17px, 24px);
      }
    }
  }
}

.elc-size-picker-wrapper {
  [data-component] & {
    display: block;
    width: 100%;
  }
}

.elc-image-nav-menu-item {
  [data-component] & {
    padding: 20px;
  }
}

.elc-product-cta-wrapper {
  .elc-add-to-bag-button,
  .elc-product-notify-me-button {
    @include elc-button--dark;
    border-radius: 0;
    line-height: unset;
    margin: 0;
    .elc-product-quick-view-content & {
      height: 60px;
      line-height: 1;
    }
    &.elc-add-to-bag-button-disabled {
      @include elc-button--dark-disabled;
      @media #{$cr19-large-up} {
        width: 100%;
      }
    }
  }
}

// MPP
.elc-elc-caption,
.elc-elc-overline {
  [data-component] & {
    font-family: $base-font-family-roman;
  }
}

.elc-grid-container,
.sd-search-results,
.elc-search-grid-wrapper {
  .elc-product-item-wrapper {
    &.elc-product-detail-wrapper {
      flex: initial;
    }
  }
  .elc-product {
    &-details-section {
      .elc-short-description-container {
        h2.elc-product-short-description-wrapper {
          @media #{$cr19-large-up} {
            @include ellipsisMultilineText(3);
          }
        }
      }
    }
    &-brief {
      position: relative;
      .elc-product-image-badge-wrapper {
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      .elc-product-images-wrapper {
        min-height: 100vw;
        width: 100%;
        @media #{$cr19-medium-up} {
          min-height: 25vw;
        }
        @media #{$cr19-xxxlarge-up} {
          max-height: 100%;
          min-height: 542.58px;
        }
        .elc-grid-item-featured-product & {
          @media #{$cr19-medium-up} {
            min-height: 45vw;
          }
          @media #{$cr19-xlarge-up} {
            min-height: 635px;
          }
        }
        .elc-img {
          background-color: $cr19-bg-light;
        }
      }
      .elc-product-image-section {
        .elc-grid-item-featured-product & {
          @media #{$cr19-xlarge-up} {
            min-height: 1281.05px;
          }
        }
      }
      .elc-product-installments-price {
        padding: 0;
        span {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .elc-product-installments-price-label {
        width: 100%;
      }
    }
    &-brief-detail-wrapper {
      .elc-product-item-wrapper {
        @include text-body-text--no-xl;
        flex: unset;
        text-align: $ldirection;
        .elc-product {
          &-display-name-link {
            @include text-body-text--no-xl;
            color: $color-cl-light-black;
          }
          &-original-price,
          &-price,
          &-price-range-row-wrapper p {
            @include text-body-text--no-xl;
            color: $cr19-text-grey;
          }
          &-shades-number,
          &-original-price,
          &-price,
          &-price-range-row-wrapper p {
            color: $cr19-text-grey;
            font-weight: normal;
          }
          &-original-price {
            padding-#{$rdirection}: 10px;
          }
          &-rating-wrapper {
            display: flex;
            justify-content: flex-start;
          }
          &-shades-number {
            text-transform: capitalize;
          }
        }
        &.elc-product-rating-wrapper {
          & + .elc-product-quick-view-shortcut {
            margin-top: -40px;
          }
        }
      }
    }
    &-quick-view-shortcut {
      cursor: pointer;
      text-align: end;
    }
    &-brief-wrapper,
    &-brief-wrapper:hover {
      box-shadow: none;
      padding: 0;
      margin: 0;
    }
  }
  .elc-product-tout-wrapper {
    .elc-basic-tout {
      &-content-below-block,
      &__media-wrapper {
        height: auto;
      }
      &__content-over-media-block {
        height: auto;
        align-items: center;
        @media #{$cr19-large-up} {
          align-items: inherit;
          position: absolute;
          top: 0;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
      }
      .basic-tout {
        &__wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
        }
        &__media-wrapper {
          .elc-grid-container & {
            height: 100%;
          }
        }
        &__media {
          height: 100%;
          .elc-img {
            height: auto;
          }
        }
        &__content-above-block,
        &__content-below-block,
        &__content-over-media {
          .content-block__links {
            margin-top: 25px;
          }
        }
        &__content-above-block,
        &__content-below-block {
          .content-block__line--header {
            padding-bottom: 10px;
          }
        }
        &__content-above-block {
          height: auto;
          padding-bottom: 32px;
          padding-top: 15px;
        }
        &__content-below-block {
          display: block;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        &__content-wrapper {
          position: relative;
        }
        &__content-over-media {
          position: absolute;
          top: 0;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          .content-block__line {
            padding-bottom: 0;
          }
          .content-block__line--content {
            margin-top: 16px;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.elc-product-badge-wrapper {
  position: absolute;
  top: 10px;
  color: $cr19-bg-dark;
  @if $badge-bold {
    #{$ldirection}: 10px;
    width: auto;
    padding: 5px;
  } @else {
    width: 100%;
    @include swap_direction(padding, 5px 5px 5px 20px);
  }
  .elc-product-badge {
    @include text-special-body($font-family: $nitti-medium-font-family);
    float: #{$ldirection};
    text-transform: uppercase;
  }
  .elc-product-image-badge {
    float: #{$rdirection};
  }
  &.elc-product-badge-above-slider {
    .elc-product-badge {
      display: none;
    }
  }
}

.elc-stars-simplified {
  height: $ratingSize;
  position: relative;
  width: $ratingWidth;
  &::before,
  &-stars::before {
    background: url('#{$base-theme-path}img/icons/src/star.svg') repeat-x;
    background-size: $ratingSize;
    content: '';
    display: block;
    height: $ratingSize;
    position: absolute;
    top: 0;
    width: $ratingWidth;
    filter: brightness(0);
  }
  &::before {
    filter: brightness(0.85);
  }
  &-stars {
    display: block;
    height: $ratingSize;
    position: absolute;
    overflow: hidden;
    width: $ratingWidth;
  }
  [dir='rtl'] &-stars {
    display: flex;
  }
}

.elc-slider-arrow-left,
.elc-slider-arrow-right {
  width: 15px;
}

.elc-slider-arrow-wrapper {
  &.slick-prev {
    left: -35px;
  }
}

.elc-grid-item-featured-product {
  .elc-product-image-section {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .elc-product-brief {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.elc-product {
  &-brief {
    .elc-product-image-section {
      background-color: $cr19-bg-light;
      &:hover {
        .elc-product-badge-wrapper {
          display: none;
        }
      }
    }
    .elc-product-images-wrapper {
      width: 100%;
    }
  }
}

.elc-grid-container {
  .elc-product-brief {
    &:hover {
      .elc-product-details-section {
        .elc-clickable-wrapper + .elc-product-quick-view-button-wrapper {
          @media #{$cr19-large-up} {
            flex: 1;
            .elc-product-quick-view-button {
              width: auto;
              float: #{$rdirection};
            }
          }
        }
        @media #{$cr19-large-up} {
          display: flex;
          flex-flow: column;
          padding-bottom: 32px;
        }
        .elc-product-short-description-wrapper,
        .elc-dynamic-data-field-element {
          @include text-body-text--no-xl;
          color: $color-cl-light-black;
          font-weight: 400;
        }
      }
      .elc-product-name-section {
        @media #{$cr19-large-up} {
          display: none;
        }
      }
    }
    .elc-product-details-section {
      margin: 0 0 20px;
      @media #{$cr19-large-up} {
        @if $quick-view-long-copy {
          display: block;
        } @else {
          display: flex;
          align-items: flex-start;
        }
      }
      @media #{$cr19-large-up} {
        margin: 10px 0 0;
        display: none;
      }
      .elc-product-quick-view-icon-wrapper,
      .elc-product-shades-count-wrapper,
      .elc-product-short-description-wrapper {
        display: none;
        @media #{$cr19-large-up} {
          display: block;
          width: 100%;
        }
      }
      .elc-product-shades-count-wrapper {
        @include text-special-body;
        text-transform: uppercase;
        font-weight: 400;
      }
      .elc-clickable-wrapper {
        &.elc-product-shades-count-wrapper,
        &.elc-rating-component-container {
          margin: 0;
          @media #{$cr19-medium-up} {
            margin-top: 12px;
          }
        }
      }
    }
    .elc-product-name-section {
      margin-top: 10px;
    }
  }

  .elc-grid-item-product {
    .elc-product-badge-wrapper {
      z-index: auto;
    }
  }
  .elc-product-name-section {
    .elc-clickable-wrapper {
      h1.elc-product-display-name-link {
        margin-bottom: 8px;
      }
      .elc-product-original-price {
        -webkit-font-smoothing: auto;
      }
      .elc-product-price-range {
        margin-top: 16px;
      }
      &.elc-product-prices-clickable-wrapper {
        margin-top: 4px;
        @media #{$cr19-medium-up} {
          margin-top: 8px;
        }
      }
    }
  }
  .elc-size-picker-container {
    border: none;
    position: relative;
    .sd-product-spp__product-cross-sell &,
    .sd-search-gnav-input-field & {
      display: none;
    }
    .elc-selected-size-label {
      display: none;
    }
    &.single-size {
      .elc-selected-size {
        display: block;
      }
    }
    .elc-selected-size {
      @include text-special-body;
      font-weight: 400;
      text-transform: uppercase;
      display: none;
      position: absolute;
      top: -18px;
      #{$rdirection}: 0;
      width: 60%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: $rdirection;
    }
    .elc-selected-size-space-row {
      margin: 0;
    }
    .elc-slider-view-wrapper {
      margin-bottom: 10px;
      margin: 10px 0;
    }
    .elc-size-picker-box,
    .elc-size-picker-box-selected {
      @include swap_direction(margin, 0 12px 0 0);
      border: 2px solid $cr19-text-grey;
      border-radius: 0;
      color: $cr19-text-grey;
      width: auto;
    }
    .elc-size-picker-box-selected {
      border: 2px solid $color-black;
      background: $color-white;
      color: $color-black;
      width: auto;
    }
    .slick-track {
      justify-content: normal;
      .slick-slide {
        width: auto !important;
        padding: 0;
      }
    }
  }
  .elc-shades-dropdown-component {
    margin: 12px 0 8px;
    .sd-product-spp__product-cross-sell &,
    .sd-search-gnav-input-field & {
      display: none;
    }
    & + .elc-size-picker-container {
      .elc-selected-size {
        top: -85px;
      }
    }
    .elc-product-shade-picker-dropdown {
      width: 100%;
      font-family: $base-bolder-font-family;
      [dir='rtl'] & {
        width: fit-content;
        .elc-dropdown-readonly-input {
          display: inline;
        }
        .elc-dropdown-arrow {
          border-#{$ldirection}: 1px solid $cr19-border-light;
        }
      }
      .elc-dropdown-arrow {
        padding-#{$rdirection}: 20px;
      }
      .elc-dropdown-wrapper {
        border: 1px solid $color-black;
        border-radius: 0;
        height: 45px;
      }
      .elc-dropdown-wrapper,
      .elc-dropdown-input-wrapper {
        padding-#{$ldirection}: 14px;
      }
      .elc-dropdown-icon-container {
        padding: 0;
      }
      .elc-dropdown-options {
        position: relative;
        &-list {
          border: 2px solid $color-grey;
          border-top: 1px solid $color-grey;
          max-height: 200px;
          bottom: auto;
        }
        .elc-dropdown-option {
          border-bottom: 2px solid $color-grey;
          border-top: 0;
          justify-content: flex-start;
          @include text-body-text--no-xl;
          text-transform: none;
          &:last-child {
            border: none;
          }
        }
      }
      .elc-dropdown-readonly-input {
        justify-content: flex-start;
      }
    }
  }
  .elc-product-shade-picker-dropdown-shade-hex,
  .elc-product-shade-picker-dropdown-shade-image {
    width: 16px;
    height: 16px;
  }

  .elc-rating-component-container {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    .elc-rating-component {
      width: inherit;
      .elc-rating {
        margin: 0;
      }
    }
  }

  .elc-product-full-cta-wrapper {
    .sd-product-spp__product-cross-sell &,
    .sd-search-gnav-input-field & {
      display: none;
    }
    position: relative;
    padding: 0;
    display: inline-block;
    z-index: 1;
    box-shadow: none;
    .elc-add-to-bag-button {
      @include text-link--style-1;
      background: none;
      border: none;
      text-align: $rdirection;
      &:hover {
        background: none;
        border: none;
      }
      &::after {
        content: '';
        direction: ltr;
        height: 18px;
        padding: 3px 12px;
        margin-#{$ldirection}: 5px;
        background: url('#{$base-theme-path}/img/icons/src/bag-with-plus.svg') no-repeat center;
      }
    }
    .elc-button.elc-product-notify-me-button {
      @include text-link--style-1;
      text-align: $rdirection;
    }
  }

  .elc-rating-component {
    display: inline-block;
    width: 80%;
    margin: 0;
  }
  .elc-slider-view-wrapper {
    .elc-product-quick-view-button-wrapper {
      margin-#{$ldirection}: 0;
    }
  }
  .elc-product {
    &-details-section,
    &-name-section {
      padding-#{$ldirection}: 0;
      justify-content: flex-start;
      flex: initial;
      @media #{$cr19-xlarge-up} {
        min-height: 170px;
      }
      @media #{$cr19-large-up} {
        min-height: 196px;
      }
      .elc-clickable-wrapper {
        @include text-body-text--no-xl;
        flex: unset;
        text-align: $ldirection;
        .elc-product {
          &-display-name-link {
            @include text-body-text--no-xl;
            color: $color-cl-light-black;
          }
          &-original-price,
          &-price,
          &-price-range-row-wrapper p {
            @include text-body-text--no-xl;
            color: $cr19-text-grey;
          }
          &-shades-number,
          &-original-price,
          &-price,
          &-price-range-row-wrapper p {
            @include text-special-body;
            font-weight: 400;
          }
          &-original-price {
            padding-#{$rdirection}: 10px;
          }
          &-rating-wrapper {
            display: flex;
            justify-content: flex-start;
          }
          &-shades-number {
            text-transform: capitalize;
          }
          &-name-wrapper,
          &-price-range-row-wrapper {
            margin-top: 0;
            margin-bottom: 0;
          }
          &-prices-wrapper,
          &-prices-clickable-wrapper,
          &-price-per-unit-row-wrapper {
            display: block;
            margin: 0;
          }
          &-short-description-wrapper {
            display: none;
            @media #{$cr19-xlarge-up} {
              display: block;
              margin: 0;
            }
          }
          &-prices-wrapper,
          &-prices-clickable-wrapper {
            width: 100%;
          }
        }
      }
    }
    &-description-wrapper {
      max-height: 115px;
      overflow: hidden;
      @media #{$cr19-xlarge-up} {
        max-height: 70px;
      }
    }
    &-quick-view-shortcut {
      cursor: pointer;
      fill: $cr19-text-grey;
      .elc-product-quick-view-container-wrapper {
        background-color: transparent;
        padding-top: 5px;
        width: 30px;
        border-radius: 15px;
        margin-#{$rdirection}: -5px;
        margin-top: 0;
      }
      &:hover {
        fill: $color-cl-light-black;
        .elc-product-quick-view-container-wrapper {
          background-color: $cr19-bg-light;
        }
      }
      &:active {
        fill: $cr19-text-black;
        .elc-product-quick-view-container-wrapper {
          background-color: $cr19-bg-light;
        }
      }
    }
    &-quick-view-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      .elc-product-quick-view-button {
        @include swap_direction(padding, 0 8px 0 0);
        font-size: 12px;
        height: 30px;
        font-family: $base-font-family-roman;
        text-transform: uppercase;
        border: 1px solid $color-white;
        &::before {
          background-color: $color-black;
          content: '';
          padding: 3px 10px;
          margin: 0 5px;
          mask: url('#{$base-theme-path}/img/icons/src/quickview.svg') no-repeat center;
          -webkit-mask: url('#{$base-theme-path}/img/icons/src/quickview.svg') no-repeat center;
        }
      }
    }
    &-brief-wrapper,
    &-brief-wrapper:hover {
      box-shadow: none;
      padding: 0;
      margin: 0;
      max-width: none;
    }
    &--badge-wrapper {
      top: 11px;
      #{$ldirection}: 24px;
    }
  }
  .elc-product-grid--filter-results-count-label {
    color: $cr19-text;
  }
}

.elc-rating-quick-view-shortcut-container {
  margin-top: 5px;
  .elc-product-styled-rating-container {
    margin-top: 0;
    justify-content: flex-start;
  }
}

.elc-rating {
  .elc-star,
  .elc-star-overlay {
    background-color: $cr19-border-light;
    height: 12px;
    width: 12px;
  }
  .elc-star-overlay {
    background-color: $color-black;
    span {
      margin-#{$rdirection}: 3px;
    }
  }
  .elc-average-rating-text {
    display: none;
  }
  .elc-rating-reviews {
    font-weight: normal;
    @include swap_direction(margin, 0 10px 0 5px);
    .elc-reviews-number {
      @include text-special-body;
    }
  }
  .elc-read-reviews {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.elc-product-shade-picker-dropdown {
  .elc-dropdown-option {
    border-top: 0.01em solid $cr19-border-light;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    text-align: $rdirection;
    > div {
      margin: 0 8px;
    }
  }
  .elc-dropdown-arrow {
    border: none;
  }
}

// Product Notify Me Popup
.elc-product-notify-me-pop-up-wrapper {
  .elc-body--2,
  .elc-input-field,
  .elc-floating-label-string,
  .elc-product-notify-me-confirmation-title {
    @include text-body-text--no-xl;
    color: $cr19-text;
    font-style: normal;
  }
  .elc-product-notify-me-pop-up-close-button {
    background-color: transparent;
  }
  .elc-input-field {
    border-radius: 0;
  }
  .elc-input-error .elc-elc-caption {
    font-family: $base-font-family-roman;
    font-size: 10px;
    line-height: get-line-height(15px, 20px);
    @media #{$cr19-medium-up} {
      font-size: 11px;
      line-height: get-line-height(17px, 24px);
    }
  }
  .elc-product-notify-me-submit-button {
    @include elc-button--dark;
    border-radius: 0;
    text-transform: inherit;
    padding: 0;
    height: 56px;
    width: 100%;
    line-height: 56px;
    border: none;
    &:disabled {
      color: $color-white;
    }
    @media #{$cr19-large-up} {
      height: 64px;
      width: 100%;
      line-height: 64px;
    }
  }
}

.elc-product-notify-me-confirmation-wrapper {
  .elc-body--2 {
    @include text-body-text--no-xl;
    color: $cr19-text;
    font-style: normal;
  }
  .elc-product-notify-me-confirmation-title {
    @include text-title--small;
  }
  .elc-product-notify-me-confirmation-close-button {
    background-color: transparent;
  }
}

// Cross Sells
.elc-product-cross-sell {
  .elc-slider-dots-list {
    display: none;
  }
}

// order components
.elc-carousel-section-wrapper {
  order: 0;
}

.elc-product-full-base-info {
  order: 2;
  @media #{$cr19-medium-up} {
    width: 100%;
    order: 1;
  }
}

.elc-sidebar-button-section {
  order: 5;
}

.elc-size-picker-container {
  width: 100%;
  order: 4;
  border-bottom: 1px solid $cr19-border-light;
}

.elc-quantity-selector-button {
  order: 6;
  width: 100%;
}

.elc-product-auto-replenish {
  margin-top: 16px;
  width: 100%;
  order: 7;
  color: $color-black;
  text-align: #{$ldirection};
  border: none;
  @media #{$cr19-large-up} {
    order: 6;
  }
  p {
    @include text-title--small;
    font-size: 15px;
    text-transform: uppercase;
    padding-#{$ldirection}: 5px;
    letter-spacing: 0.02em;
    @media #{$cr19-large-up} {
      font-size: 11px;
    }
  }
  &-wrapper {
    position: relative;
    @media #{$cr19-large-up} {
      display: flex;
      align-items: baseline;
    }
    .elc-dropdown-wrapper > div,
    .elc-dropdown-input-wrapper > div {
      @include text-title--small;
      color: $color-black;
      padding: 0;
      @media #{$cr19-medium-up} {
        font-size: 15px;
        line-height: get-line-height(15px, 24px);
      }
    }
  }
  .elc-auto-replenish {
    &-subscribe-copy-wrapper,
    &-dropdown-wrapper {
      margin-top: 4px;
      padding-#{$ldirection}: 25px;
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 5px;
        font-size: 13px;
        margin: 0;
      }
    }
    &-subscribe-wrapper,
    &-switch-wrapper {
      text-align: #{$rdirection};
      @media #{$cr19-large-up} {
        margin: 0;
      }
      .elc-switch {
        display: inline-flex;
        border: none;
        &-circle {
          border: none;
        }
      }
    }
    &-subscribe-wrapper,
    &-subscribe-copy-wrapper,
    &-dropdown-wrapper {
      @include text-body-text--fine;
      color: $cr19-text;
      margin: 0;
      width: auto;
      text-align: $ldirection;
    }
    &-dropdown-wrapper {
      position: absolute;
      top: 5px;
      #{$rdirection}: 0;
      #{$ldirection}: 102px;
      display: flex;
      justify-content: $rdirection;
      @media #{$cr19-large-up} {
        display: flex;
        position: relative;
        #{$ldirection}: 0;
        top: -5px;
        height: 20px;
      }
      .elc-dropdown-disabled {
        display: none;
      }
    }
    &-open-modal {
      background: transparent;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      padding: 0;
      #{$ldirection}: 0;
      @media #{$cr19-large-up} {
        position: relative;
        min-width: auto;
        top: 2px;
      }
      &:hover {
        background: transparent;
      }
      .elc-auto-replenish-modal-trigger-icon {
        display: none;
      }
      &::after {
        background: url('#{$base-theme-path}img/icons/src/information.svg') no-repeat center;
        #{$rdirection}: 0;
        content: '';
        display: block;
        height: 13px;
        width: 13px;
      }
      &.elc-button {
        line-height: 1;
        width: 20px;
        height: 20px;
        min-width: auto;
      }
    }
  }
  .elc-dropdown-wrapper,
  .elc-dropdown-input-wrapper {
    border: none;
    padding: 0;
    .elc-dropdown-arrow {
      display: none;
    }
    .elc-dropdown-options-list {
      border: none;
      min-width: 200px;
      #{$ldirection}: auto;
      @media #{$cr19-medium-up} {
        min-width: 220px;
        margin-#{$rdirection}: 10px;
      }
      .elc-dropdown-option {
        border: 1px solid $cr19-border-light;
      }
    }
  }
  .elc-dropdown-input-wrapper {
    @include dropdown-opt;
    height: 24px;
    padding: 0;
    width: 230px;
  }
  .elc-checkbox-icon {
    height: 21px;
    width: 21px;
  }
}

.elc-product-installments-price-label {
  order: 7;
}

.elc-product-prices-wrapper,
.elc-product-prices-clickable-wrapper {
  order: 7;
  width: 100%;
  @media #{$cr19-medium-up} {
    width: 50%;
  }
  @media #{$cr19-xlarge-up} {
    margin-#{$rdirection}: 50%;
    margin-top: 17px;
  }
}

.elc-product-quick-view-details-wrapper,
.elc-right-column-bottom-cta-section {
  // to be removed in the future, needed now for backward compatibility
  .elc-product-cta-wrapper {
    .elc-add-to-bag-sku-inventory-status-message {
      order: 8;
      margin: 24px 0;
      width: 100%;
      text-align: #{$ldirection};
      min-height: auto;
    }
  }
  .elc-add-to-bag-sku-inventory-status-message {
    margin: 0 0 10px;
    width: 100%;
    align-self: flex-end;
    text-align: #{$rdirection};
    .elc-add-to-bag-sku-inventory-status-message-message {
      color: $color-cl-light-black;
      font-weight: bold;
    }
  }
}

.elc-product-installments-price {
  order: 10;
  width: 100%;
  margin-#{$ldirection}: 0;
  .elc-product-installments-price-logo {
    width: 100px;
    height: 20px;
  }
  .elc-product-installments-price-open-modal {
    border: none;
    background-color: $color-btn-inverse;
    height: auto;
    min-width: auto;
    width: auto;
    margin-#{$ldirection}: 6px;
    &.elc-button {
      height: auto;
      width: auto;
      min-width: auto;
    }
    &:hover {
      background-color: $color-btn-inverse;
    }
    &:focus {
      outline: none;
    }
  }
  &-label {
    width: 100%;
    display: inline-block;
  }
  div {
    .elc-product-installments-price-open-modal {
      margin: 0;
    }
  }
}

.elc-social-share {
  order: 11;
  width: 100%;
}

.elc-product-overview {
  order: 12;
}

.elc-product-ingredients {
  order: 13;
  width: 100%;
}

.elc-auto-replenish-modal-close-button-icon {
  cursor: pointer;
  border: none;
  outline: none;
  margin: 10px;
}

.elc-product-quick-view-wrapper,
.product-full {
  .elc-dropdown-wrapper,
  .elc-dropdown-input-wrapper,
  .elc-size-picker-label {
    .elc-size {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .elc-formatted-price {
      margin-#{$rdirection}: 8px;
    }
  }
  .elc-size-picker-label {
    .elc-price-and-size {
      width: 100%;
    }
  }
}

.elc-product-tax-included-label-wrapper {
  display: inline-block;
  margin-#{$rdirection}: 5px;
}

// QS ORDERABLE STYLES
.elc-product-quick-view-wrapper {
  max-width: $foundation-finder-max-width;
  .elc-shades-dropdown-component {
    box-shadow: $color-black-12opacity 0 1px 0 0;
    margin-bottom: 20px;
    .elc-product-shade-picker-dropdown {
      width: 50%;
      margin: 36px 0;
      font-family: $base-bolder-font-family;
      [dir='rtl'] & {
        width: fit-content;
        .elc-dropdown-readonly-input {
          display: inline;
        }
        .elc-dropdown-arrow {
          border-#{$ldirection}: 1px solid $cr19-border-light;
        }
      }
      .elc-dropdown-wrapper,
      .elc-dropdown-input-wrapper {
        padding-#{$ldirection}: 5px;
      }
    }
  }
  .elc-product-shade-picker-dropdown-shade-hex,
  .elc-product-shade-picker-dropdown-shade-image {
    width: 16px;
    height: 16px;
  }
  .elc-grid-column {
    padding: 0;
    display: inline-flex;
  }
  .elc-size-picker-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    margin-top: 20px;
    &.elc-single-size {
      flex-direction: unset;
    }
    .elc-size-picker-box,
    .elc-size-picker-box-selected {
      height: 30px;
    }
    .elc-size-picker-label,
    .elc-select-a-size {
      @include text-body-text--no-xl;
      justify-content: flex-start;
      p {
        font-size: inherit;
        padding: 2px 0;
        font-family: inherit;
      }
    }
    .elc-dropdown {
      .elc-dropdown-wrapper,
      .elc-dropdown-input-wrapper {
        font-family: $base-bolder-font-family;
        font-size: 17px;
      }
      .elc-dropdown-option {
        font-family: $base-font-family-roman;
      }
    }
  }
  .elc-dropdown-wrapper,
  .elc-dropdown-input-wrapper {
    .elc-price-and-size {
      width: 75%;
    }
  }
  .elc-product-display-name {
    @include text-title--med--no-xl;
    [dir='rtl'] & {
      display: inherit;
      flex-direction: column;
    }
    margin-bottom: 8px;
    max-height: 130px;
    min-height: 90px;
    padding-#{$rdirection}: 20px;
    &-link {
      font-family: $base-bolder-font-family;
      font-size: 28px;
      line-height: 1.14286;
      letter-spacing: -0.02em;
      margin: 0;
      &:hover {
        text-decoration: underline;
      }
    }
    &-wrapper {
      width: 100%;
    }
  }
  .elc-product-learn-more-link {
    @include swap_direction(margin, 0 0 0 4px);
    font-family: $base-font-family-roman;
    font-size: 17px;
    color: $color-cl-light-black;
    padding-#{$rdirection}: 4px;
    text-decoration: underline;
    [dir='rtl'] & {
      text-align: right;
    }
  }
  .elc-product-short-description-wrapper {
    font-family: $base-font-family-roman;
    display: inline;
    font-size: 17px;
    line-height: 1.41176;
    letter-spacing: 0.02em;
  }
  .elc-product-quick-view-close-button {
    background-color: $cr19-bg-white;
  }
  .elc-productquick-view-content {
    align-items: flex-start;
  }
  .elc-product-shade-picker-wrapper {
    box-shadow: $color-black-12opacity 0 1px 0 0;
    margin-bottom: 20px;
  }
  .elc-product-rating-wrapper {
    justify-content: flex-start;
  }
  .elc-product-price-row-wrapper {
    flex-direction: column;
    .elc-product-original-price,
    .elc-product-price {
      @include text-title--med--no-xl;
      color: $cr19-text;
      font-weight: normal;
      padding-#{$rdirection}: 10px;
    }
  }
  .elc-quantity-selector-button {
    justify-content: flex-start;
    margin-#{$ldirection}: 0;
    padding-top: 5px;
    width: 44%;
    align-self: flex-start;
    margin-bottom: 25px;
    .elc-quantity-selector-label {
      @include text-title--small;
      width: auto;
      margin-#{$rdirection}: 10px;
    }
    .elc-spinner-selector,
    .elc-spinner-selector-data-wrapper {
      border: none;
    }
    .elc-spinner-minus-icon,
    .elc-spinner-plus-icon {
      width: 12px;
      height: 12px;
    }
    .elc-spinner-selector-data {
      @include text-title--small;
      @media #{$cr19-xlarge-up} {
        font-size: 17px;
        line-height: get-line-height(17px, 24px);
      }
    }
  }
  .elc-product-name-wrapper {
    [dir='rtl'] & {
      display: inherit;
    }
  }
  .elc-product-quick-view-content {
    &.elc-product-quick-view-content-orderable {
      display: grid;
      grid-template-columns: calc(52% + 45px) 48%;
      grid-template-areas:
        'left right-top'
        'left right-center'
        'left right-bottom'
        'left .'
        'left right-bottom-cta';
      .elc-product-auto-replenish {
        &-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          position: relative;
          margin-bottom: 50px;
          p {
            @media #{$cr19-large-up} {
              font-size: 13px;
            }
          }
        }
        .elc-dropdown {
          &-wrapper > div,
          &-input-wrapper > div {
            display: block;
            max-width: 154px;
            font-size: 14px;
            @media #{$cr19-large-up} {
              max-width: unset;
              font-size: 15px;
            }
          }
          &-arrow {
            padding: 0 5px;
            @media #{$cr19-large-up} {
              padding: 0 16px;
            }
          }
        }
        .elc-checkbox-check-area {
          top: 5px;
        }
        .elc-auto-replenish {
          &-subscribe-copy-wrapper,
          &-dropdown-wrapper {
            padding-#{$ldirection}: 0;
            @media #{$cr19-large-up} {
              font-size: 13px;
              margin: 0;
            }
          }
          &-subscribe-copy-wrapper {
            position: absolute;
            top: 30px;
            #{$ldirection}: 25px;
          }
          &-dropdown-wrapper {
            display: flex;
            flex-direction: row-reverse;
            flex: 1;
            justify-content: space-between;
            position: unset;
            height: 30px;
            align-items: center;
          }
          &-subscribe-wrapper {
            margin: 0;
            flex: none;
            height: 30px;
          }
          &-open-modal {
            position: inherit;
            &.elc-button {
              height: auto;
              border-radius: 0;
            }
          }
        }
      }
      .elc-product-installments-price {
        &-price-open-modal {
          &.elc-button {
            order: 1;
            margin: 0;
          }
        }
      }
    }
  }
  .elc-left-column-section {
    grid-area: left;
    min-height: 572px;
  }
  .elc-right-column-top-section {
    grid-area: right-top;
    padding-#{$rdirection}: 44px;
  }
  .elc-right-column-center-section {
    grid-area: right-center;
    padding-#{$rdirection}: 44px;
  }
  .elc-right-column-bottom-section {
    grid-area: right-bottom;
    padding-#{$rdirection}: 44px;
  }
  .elc-right-column-bottom-cta-section {
    grid-area: right-bottom-cta;
    padding-#{$rdirection}: 44px;
  }
  .elc-left-column-section,
  .elc-right-column-section {
    position: relative;
  }
  .elc-left-column-section {
    @include swap_direction(margin, 48px 0 48px 64px);
    .elc-carousel-products {
      .elc-slider-dots-list {
        @include swap_direction(margin, 0 0 0 25px);
        text-align: $ldirection;
        border: none;
        bottom: 17px;
        .elc-slider-dot-button {
          &::before {
            font-size: 8px;
            opacity: 0.25;
            color: $color-black;
          }
        }
        .elc-slider-dot-button-active {
          &::before {
            opacity: 0.75;
          }
        }
        li {
          margin: 0;
        }
      }
      .elc-product-images-wrapper {
        background-color: $color-grey-light;
      }
    }
  }
  .elc-right-column-top-section,
  .elc-right-column-center-section,
  .elc-right-column-bottom-section,
  .elc-right-column-bottom-cta-section {
    .elc-clickable-wrapper {
      box-sizing: border-box;
    }
  }
  .elc-right-column-center-section,
  .elc-right-column-bottom-section,
  .elc-right-column-bottom-cta-section {
    margin-#{$ldirection}: 24px;
  }
  .elc-right-column-top-section {
    @include swap_direction(margin, 48px 0 0 24px);
    .elc-clickable-wrapper {
      .elc-product-name-wrapper {
        @include swap_direction(margin, 12px 0 0 0);
      }
    }
  }
  .elc-right-column-center-section {
    display: block;
  }
  .elc-right-column-bottom-section {
    .elc-size-picker-container {
      width: 100%;
      border: none;
    }
  }
  .elc-right-column-bottom-cta-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 88px;
    @media #{$cr19-large-up} {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: calc(100% - 220px) 220px;
      grid-template-areas:
        'inventory inventory'
        'price cta';
    }
    .elc-product-full-cta-wrapper {
      align-self: flex-end;
      border-radius: 0;
      justify-content: flex-end;
      margin-top: auto;
      order: 2;
      padding: 0;
      width: 100%;
      z-index: 1;
      @media #{$cr19-large-up} {
        grid-area: cta;
        justify-content: flex-start;
        height: 100%;
      }
    }
    .elc-product-prices-wrapper,
    .elc-product-prices-clickable-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: min-content;
      justify-content: center;
      order: 1;
      width: auto;
      @media #{$cr19-large-up} {
        grid-area: price;
        height: 100%;
        margin: 0;
        order: 0;
        width: 100%;
      }
      .elc-product-prices-wrapper {
        display: flex;
        height: auto;
      }
    }
    .elc-product-original-price,
    .elc-product-price {
      @include text-title--med--no-xl;
      color: $cr19-text;
      font-weight: normal;
      padding-#{$rdirection}: 10px;
    }
    .elc-product-price-per-unit {
      font-family: $base-font-family-roman;
      font-size: 16px;
      color: $cr19-text;
    }
    .elc-add-to-bag-sku-inventory-status-message {
      order: 1;
      grid-area: inventory;
      text-align: $ldirection;
      margin: 0;
      @media #{$cr19-large-up} {
        order: 0;
        text-align: $rdirection;
      }
    }
  }
}

// Loyalty Program specific styles
@if $loyalty_program == true {
  .elc-product-loyalty-message {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    max-width: 400px;
    order: 6;
    width: 100%;
    @media #{$cr19-medium-up} {
      margin-bottom: 20px;
    }
    + .elc-product-prices-wrapper,
    + .elc-product-prices-clickable-wrapper {
      margin: 0 0 20px;
      max-width: 400px;
      .elc-product-prices-wrapper {
        display: flex;
        flex-direction: row-reverse;
        .elc-product-original-price {
          font-size: 18px;
          width: 50%;
        }
        .elc-product-tax-included-label-wrapper {
          font-size: 18px;
          margin: 0;
          text-align: $rdirection;
          width: 50%;
        }
        .elc-product-price-row-wrapper {
          margin: 0;
          width: 50%;
        }
      }
      ~ .elc-product-full-cta-wrapper {
        max-width: 400px;
        width: 100%;
        .elc-add-to-bag-button {
          width: 100%;
        }
      }
    }
    .elc-product-loyalty-message-price {
      display: flex;
      font-size: 18px;
      @media #{$cr19-large-up} {
        flex-direction: column;
      }
      @media #{$cr19-xlarge-up} {
        flex-direction: initial;
      }
      .loyalty-label-short {
        display: none;
      }
      .loyalty-price {
        width: 30%;
      }
      .loyalty-label-full {
        width: 70%;
        white-space: nowrap;
      }
    }
    .elc-button {
      &.elc-product-loyalty-message-modal {
        &-open:hover,
        &-open {
          width: auto;
          height: 0;
          background: transparent;
          min-width: auto;
          line-height: 1;
          margin: -10px;
        }
      }
    }
    .elc-product-loyalty-message-link {
      font-size: 17px;
      font-weight: bold;
      margin: 25px 0;
      @media #{$cr19-medium-up} {
        margin: 15px 0 25px;
      }
      @media #{$cr19-xlarge-up} {
        margin: 20px 0 25px;
      }
    }
  }
  .elc-product-quick-view-wrapper .elc-product-quick-view-content {
    .elc-right-column-bottom-cta-section {
      .elc-product-loyalty-message {
        order: 1;
      }
      .elc-product-prices-wrapper,
      .elc-product-prices-clickable-wrapper {
        order: 2;
      }
      .elc-product-loyalty-message + .elc-product-prices-wrapper ~ .elc-product-full-cta-wrapper,
      .elc-product-loyalty-message
        + .elc-product-prices-clickable-wrapper
        ~ .elc-product-full-cta-wrapper {
        order: 4;
      }
    }
  }
  // Loyalty Modal Styles
  .elc-button {
    &.elc-product-loyalty-message-modal {
      &-close:hover,
      &-close {
        width: 10px;
        height: 10px;
        background: transparent;
        min-width: auto;
        #{$rdirection}: 0;
        float: $rdirection;
        line-height: 1;
      }
    }
    .elc-info-icon {
      background: transparent;
      margin-bottom: -3px;
    }
  }
  .elc-product-loyalty-message-modal {
    &-close-icon {
      position: absolute;
      top: 5px;
      #{$rdirection}: 5px;
    }
  }
  // SPP Styles
  .sd-product-spp {
    .elc-right-column-section {
      .elc-product-loyalty-message {
        margin-top: 30px;
        order: 7;
        @media #{$cr19-large-up} {
          margin-top: 39px;
          width: 53%;
        }
        & + .elc-product-full-cta-wrapper {
          max-width: inherit;
          order: 10;
          padding-#{$ldirection}: 0;
          width: 100%;
          @media #{$cr19-large-up} {
            display: inline-block;
            margin-top: -22px;
            order: 9;
            width: 47%;
          }
          @media #{$cr19-xlarge-up} {
            margin-top: -42px;
          }
          & + .elc-product-prices-wrapper,
          & + .elc-product-prices-clickable-wrapper {
            height: auto;
            margin: 0 0 10px;
            max-width: 400px;
            order: 8;
            padding: 0;
            width: 100%;
            @media #{$cr19-large-up} {
              display: inline-block;
              width: 53%;
            }
            .elc-product-prices-wrapper {
              align-items: flex-start;
              display: flex;
              flex-direction: row-reverse;
              justify-content: center;
              height: auto;
              margin: 0;
              width: 100%;
              @media #{$cr19-large-up} {
                flex-direction: column-reverse;
              }
              @media #{$cr19-xlarge-up} {
                flex-direction: row-reverse;
              }
              .elc-product-tax-included-label-wrapper {
                display: block;
                font-size: 18px;
                margin: 0;
                text-align: $ldirection;
                width: 70%;
              }
              .elc-product-price-row-wrapper {
                margin-#{$rdirection}: 0;
                width: 30%;
                .elc-price-formatted-wrapper {
                  width: 100%;
                  &:last-child {
                    .elc-price-formatted {
                      font-size: 18px;
                      text-align: $ldirection;
                      width: 100%;
                    }
                  }
                }
              }
            }
            + .elc-add-to-bag-sku-inventory-status-message {
              order: 9;
              @media #{$cr19-large-up} {
                order: 10;
              }
            }
          }
        }
      }
    }
  }
  .elc-product-tax-included-label-wrapper {
    display: none;
  }
  // Quick View Styles
  .elc-product-quick-view-wrapper {
    .elc-right-column-bottom-cta-section {
      div.elc-product-tax-included-label-wrapper {
        display: none;
      }
    }
    .elc-product-loyalty-message {
      font-size: 17px;
      margin-top: 0;
      max-width: 350px;
      order: inherit;
      + .elc-product-prices-wrapper,
      + .elc-product-prices-clickable-wrapper {
        height: auto;
        margin: 0 0 20px;
        max-width: 350px;
        order: inherit;
        padding: 0;
        width: 100%;
        .elc-product-prices-wrapper,
        .elc-product-prices-clickable-wrapper {
          display: flex;
          height: auto;
          margin: 0;
          width: 100%;
        }
        .elc-product-original-price {
          font-size: 17px;
        }
        .elc-product-tax-included-label-wrapper {
          display: block;
          font-size: 17px;
        }
        ~ .elc-product-full-cta-wrapper {
          max-width: 350px;
          align-self: self-start;
        }
      }
      .elc-product-loyalty-message-modal-open:hover,
      .elc-product-loyalty-message-modal-open {
        background: transparent;
        height: 0;
        min-width: auto;
        padding: 0;
        width: auto;
        margin: 0;
      }
      .elc-product-loyalty-message-price {
        font-size: 17px;
        .loyalty-price {
          width: 40%;
        }
        .loyalty-label-full {
          width: 60%;
        }
      }
    }
  }
  // MPP, Cross-sell Styles
  .sd-product-spp__product-cross-sell,
  .sd-product-grid,
  .elc-search-enhanced-grid-wrapper,
  .search-overlay-displayed .elc-search-results-wrapper,
  .sd-search-results .elc-search-wrapper {
    .elc-product-loyalty-message + .elc-product-prices-wrapper,
    .elc-product-loyalty-message + .elc-product-prices-clickable-wrapper,
    .elc-product-tax-included-label-wrapper {
      display: none;
    }
    .elc-product-loyalty-message {
      margin: 0;
      .elc-product-loyalty-message-price {
        color: $color-light-grey-2;
        flex-direction: row;
        font-size: 17px;
        .loyalty-label-short {
          display: block;
        }
        .loyalty-label-full {
          display: none;
        }
      }
    }
    .elc-grid-container {
      .elc-product-name-section {
        .elc-product-loyalty-message {
          display: inline-block;
          width: 40%;
          @media #{$cr19-large-up} {
            width: 100%;
          }
          + .elc-product-prices-clickable-wrapper {
            display: none;
            + .elc-product-shades-count-wrapper {
              width: 60%;
              @media #{$cr19-large-up} {
                width: 100%;
              }
              .elc-product-shades-count-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Global SWD discount Styles
.elc-on-sale-today-label-wrapper + .elc-product-price-row-wrapper {
  display: flex;
  margin: 0;
  .elc-discount-percent-label,
  .elc-product-original-price {
    color: $color-light-grey-2;
    font-family: $base-bolder-font-family;
    font-size: 22px;
    line-height: 1;
    margin-#{$rdirection}: 5px;
    padding-#{$rdirection}: 5px;
  }
  .elc-product-price {
    margin: 0;
    font-size: 22px;
  }
}

.elc-left-column-section {
  .elc-product-overview {
    ol {
      list-style: auto inside;
    }
    ul {
      list-style: disc inside;
    }
  }
}

.elc-product-quick-view-wrapper,
.sd-product-spp__product-cross-sell,
.sd-product-grid {
  @include quickshop;
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    .elc-on-sale-today-label-wrapper {
      font-size: 18px;
      + .elc-product-price-row-wrapper {
        display: flex;
        .elc-product-price,
        .elc-discount-percent-label,
        .elc-product-original-price {
          font-size: 17px;
          padding: 0 2px 0;
          margin: 0;
        }
      }
    }
  }
  .elc-product-badge-wrapper {
    .elc-product-badge {
      @include text-special-body-small;
      @if $badge-bold {
        color: $color-white;
      }
    }
  }
}

.sd-product-spp {
  .elc-right-column-section {
    .elc-sidebar-button-section {
      padding-#{$ldirection}: 0;
      width: 100%;
      .elc-product-shades-view-button {
        width: 100%;
        @media #{$cr19-large-up} {
          width: 50%;
        }
      }
    }
    .elc-size-picker-container {
      & + .elc-sidebar-button-section {
        width: 100%;
        @media #{$cr19-large-up} {
          padding-#{$ldirection}: 13px;
          width: 50%;
        }
        .elc-product-shades-view-button {
          width: 100%;
        }
      }
    }
  }
  .product-recommendations__grid {
    .sd-product-grid {
      @include shade-picker-style;
    }
    .elc-grid-container {
      .elc-product-brief {
        padding: 0 5px;
      }
    }
    .slick-slide {
      margin: 0;
    }
  }
}
